document.addEventListener('DOMContentLoaded', function () {
  const address = process.env.CONTRACT_ADDRESS;


  function getTruncatedAddress() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      return truncate(address, 4, 4);
    } else {
      return truncate(address, 7, 8);
    }
  }

  document.getElementById('contractAddress').textContent =
    getTruncatedAddress();

  window.addEventListener('resize', function () {
    document.getElementById('contractAddress').textContent =
      getTruncatedAddress();
  });
});

export const truncate = (value, start, end) => {
  if (value === undefined || value === '') return '';
  return `${value.substring(0, start)}...${value.substring(
    value.length - end
  )}`;
};
